<template>
    <v-col cols="12">
        <v-img v-if="curso.imagen_url && moduloIndex === null" :src="curso.imagen_url" max-height="400" cover>
        </v-img>
        <v-card class="mx-4 mt-4">
            <template v-if="moduloIndex !== null">
                <v-card-subtitle style="word-break: break-word;" class="mt-4 text-wrap"> {{ curso.titulo }}
                </v-card-subtitle>
                <v-card-actions v-if="selectedOption === 'editar'">
                    <v-btn color="primary" @click="openModuleDialog(moduloIndex)" prepend-icon="mdi-pencil">
                        {{ t('Editar') }}
                    </v-btn>
                    <v-btn color="secondary" prepend-icon="mdi-sort" @click="showSortDialog = true">
                        {{ t('Ordenar') }}
                    </v-btn>
                    <v-btn color="error" @click="confirmDelete(moduloIndex)" prepend-icon="mdi-delete">
                        {{ t('Eliminar') }}
                    </v-btn>
                </v-card-actions>
                <v-card-title style="word-break: break-word;" class="text-h4 text-wrap font-weight-bold my-2">{{
            moduls.modulos[moduloIndex].titulo }} </v-card-title>
                <v-card-text>
                    <MarkdownText :markdownText="moduls.modulos[moduloIndex].descripcion"></MarkdownText>
                </v-card-text>
            </template>
            <template v-if="moduloIndex === null">
                <v-card-title style="word-break: break-word;" class="text-h4 text-wrap font-weight-bold my-2">{{
            curso.titulo }}
                </v-card-title>
                <v-card-text>
                    <MarkdownText :markdownText="curso.presentacion"></MarkdownText>
                </v-card-text>
            </template>
        </v-card>
    </v-col>
    <template v-if="moduloIndex === null">
        <v-col cols="12" v-if="!drawer">
            <v-card class="mx-4">
                <v-card-actions>
                    <v-btn color="primary" @click.stop="openDrawer">
                        {{ t('Índice_de_contenidos') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <template v-if="selectedOptionVista === 'alumno'">
            <v-col cols="12" md="6">
                <v-card class="mx-4">
                    <v-card-title>{{ t('Tu_progreso') }}</v-card-title>
                    <v-card-text>
                        <template v-if="curso && curso.porcentaje_teorico > 0 && curso.porcentaje_teorico < 100">
                            <div>
                                {{ t('Puntuación_teórica') }} ({{ curso.porcentaje_teorico }}%) :
                                {{ curso.notas && curso.notas.puntaje_teorico !== null ? curso.notas.puntaje_teorico :
            t('Pendiente') }}
                            </div>
                            <div>
                                {{ t('Puntuación_práctica') }} ({{ 100 - curso.porcentaje_teorico }}%) :
                                {{ curso.notas && curso.notas.puntaje_practico_ponderado !== null ?
            curso.notas.puntaje_practico_ponderado : t('Pendiente') }}
                                <span v-if="curso.notas && curso.notas.puntaje_practico !== null">
                                    ({{ curso.notas.puntaje_practico }} {{ t('puntos') }})
                                </span>
                            </div>
                            <div>
                                {{ t('Nota_final') }}:
                                {{ curso.notas && curso.notas.nota_final !== null ? curso.notas.nota_final :
            t('Pendiente')
                                }}
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                {{ t('Nota_final') }}:
                                {{ curso.notas && curso.notas.nota_final !== null ? curso.notas.nota_final :
            t('Pendiente')
                                }}
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="mx-4">
                    <v-card-title class="text-wrap" style="word-break: break-word">{{ t('Actividad_dirigida_por') }} {{
            curso.nombre_director }}</v-card-title>
            <v-card-text class="text-wrap" style="word-break: break-word">{{ t('Docentes') }}: {{ curso.docentes.join(', ') }}</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card class="mx-4">
                    <CodiQR :curso="curso" :selectedOptionVista="selectedOptionVista"></CodiQR>
                </v-card>
            </v-col>
        </template>
        <template v-if="selectedOptionVista === 'docente'">
            <CodiQR :curso="curso" :selectedOptionVista="selectedOptionVista"></CodiQR>
            <ContenidoDocente></ContenidoDocente>
        </template>
    </template>

    <v-dialog v-model="showDialog" persistent>
        <v-card>
            <v-card-title>{{ t('Estás_seguro') }}</v-card-title>
            <v-card-text>
                {{ t('Estás_seguro_que_quieres_eliminar') }} el módulo "{{ moduls.modulos[pendingDeleteIndex]?.titulo
                }}"? {{
            t('Esta_acción_eliminará_todas') }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="secondary" text @click="showDialog = false">{{ t('Cancelar') }}</v-btn>
                <v-btn color="error" text @click="eliminarModulo">{{ t('Eliminar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Diálogo para editar módulo -->
    <v-dialog v-model="showModuleDialog" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ t('Editar') }} {{ t('modulos') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="actualizarModulo" prepend-icon="mdi-content-save">
                    {{ t('Guardar') }}
                </v-btn>
                <v-btn icon @click="showModuleDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field v-model="moduleForm.titulo" :label="t('Título')"
                    :rules="[v => !!v || 'Campo requerido']"></v-text-field>
                <v-textarea v-model="moduleForm.descripcion" :label="t('Descripción')"
                    :rules="[v => !!v || 'Campo requerido']" auto-grow></v-textarea>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showSortDialog" persistent max-width="600px">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ t('Reordenar') }} {{ t('modulos') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="actualizarOrden" prepend-icon="mdi-content-save">
                    {{ t('Guardar') }}
                </v-btn>
                <v-btn icon @click="showSortDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-list dense>
                    <v-list-item v-for="(modulo, index) in moduls.modulos" :key="modulo.id">
                        <v-list-item-title>
                            {{ modulo.titulo }}
                        </v-list-item-title>
                        <template v-slot:append>
                            <v-btn icon="mdi-arrow-up" @click="moveModuleUp(index)"></v-btn>
                            <v-btn icon="mdi-arrow-down" @click="moveModuleDown(index)"></v-btn>
                        </template>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script setup>
import { ref, reactive, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import MarkdownText from './MarkdownText.vue';
import ContenidoDocente from './ContenidoDocente.vue';
import CodiQR from './CodiQR.vue';
import { axios } from '../axios';


const props = defineProps({
    curso: {
        type: Object
    },
    moduls: {
        type: Object,
        default: () => ({ modulos: [] })
    },
    moduloIndex: Number,
    selectedOption: String,
    selectedOptionVista: String,
    drawer: {
        type: Boolean,
        default: true
    }
});

const showDialog = ref(false);
const pendingDeleteIndex = ref(null);
const showModuleDialog = ref(false);
const showSortDialog = ref(false);
const snackbarText = ref('');

const { t } = useI18n();
const emit = defineEmits([
    'update:drawer', 'update-modulo', 'delete-modulo', 'reorder-modules', 'triggerSnackbar', 'refrescar'
]);

function openDrawer() {
    emit('update:drawer', true);
}

const moduleForm = reactive({
    id: '',
    titulo: '',
    descripcion: '',
    tipo: props.selectedOptionVista,
    curso: props.curso.id,
});

// Observar y reaccionar a cambios en moduls.modulos
const mod = ref([]);
watchEffect(() => {
    if (props.moduls && Array.isArray(props.moduls.modulos)) {
        mod.value = props.moduls.modulos.map(modulo => ({ ...modulo }));
    }
});

async function actualizarModulo() {

    try {
        const response = await axios.put(`modulos/${moduleForm.id}/`, moduleForm);
        const index = mod.value.findIndex(m => m.id === moduleForm.id);
        emit('update-modulo', { index, data: response.data });
        showModuleDialog.value = false;
        snackbarText.value = response.data.titulo + ' ' + t("fue_actualizado_con_éxito");
        emit('triggerSnackbar', { message: snackbarText.value, color: 'success' });
    } catch (error) {
        handleAxiosError(error);
    }
}

function confirmDelete(index) {
    pendingDeleteIndex.value = index;
    showDialog.value = true;
}

async function eliminarModulo() {
    const moduloId = mod.value[pendingDeleteIndex.value]?.id;
    try {
        await axios.delete(`modulos/${moduloId}/`);
        emit('delete-modulo', pendingDeleteIndex);
        showDialog.value = false;
        snackbarText.value = t("El_módulo") + ' ' + t("se_ha_eliminado_con_éxito");
        emit('triggerSnackbar', { message: snackbarText.value, color: 'success' });
    } catch (error) {
        handleAxiosError(error);
    }
}

function openModuleDialog(index) {
    console.log(`Datos de mod.value:`, mod.value)

    moduleForm.id = mod.value[index].id;
    moduleForm.titulo = mod.value[index].titulo;
    moduleForm.descripcion = mod.value[index].descripcion;
    showModuleDialog.value = true;
}

function moveModuleUp(index) {
    if (index > 0) {
        emit('reorder-modules', { type: 'up', index });
    }
}

function moveModuleDown(index) {

    if (index < mod.value.length - 1) {
        emit('reorder-modules', { type: 'down', index });
    }
}

async function actualizarOrden() {
    const orderedModuleIds = mod.value.map(modulo => modulo.id);
    try {
        await axios.put('modulos/reorder/', {
            curso_id: props.curso.id,
            new_order: orderedModuleIds
        });
        snackbarText.value = t("Orden_guardado_con_éxito");
        emit('triggerSnackbar', { message: snackbarText.value, color: 'success' });
    } catch (error) {
        handleAxiosError(error);
    }
    showSortDialog.value = false;
}

function handleAxiosError(error) {
    if (error.response && error.response.data && typeof error.response.data === 'object') {
        let errorMessage = Object.keys(error.response.data)
            .map(key => `${key}: ${error.response.data[key].join(' ')}`)
            .join('\n');
        snackbarText.value = errorMessage;
    } else {
        snackbarText.value = error.toString();
    }
    emit('triggerSnackbar', { message: snackbarText.value, color: 'error' });
}
</script>